import * as React from "react";
import type { SVGProps } from "react";
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21 21"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeWidth={2}>
      <path
        strokeLinecap="round"
        d="M5 16v-3m5 3V7m5 9v-4"
        data-name="Caminho 323"
      />
      <path d="M20 4a3 3 0 1 1-3-3 3 3 0 0 1 3 3Z" data-name="Caminho 324" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 9.5v1c0 4.478 0 6.718-1.391 8.109S14.978 20 10.5 20s-6.718 0-8.109-1.391S1 14.978 1 10.5s0-6.717 1.391-8.109S6.022 1 10.5 1h1"
        data-name="Caminho 325"
      />
    </g>
  </svg>
);
export default SvgAnalytics;
