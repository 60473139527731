import * as React from "react";
import type { SVGProps } from "react";
const SvgPencilEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20.181 20.181"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeLinecap="round" strokeWidth={2}>
      <path
        strokeLinejoin="round"
        d="m14.214 3.163 1.4-1.4a1.982 1.982 0 0 1 2.8 2.8l-1.4 1.4m-2.8-2.8L8.98 8.397a11 11 0 0 0-1.924 2.2A19 19 0 0 0 6 14.181a19 19 0 0 0 3.579-1.056 11 11 0 0 0 2.2-1.924l5.234-5.234m-2.8-2.8 2.8 2.8"
        data-name="Caminho 380"
      />
      <path
        d="M19 10.181c0 4.243 0 6.364-1.318 7.682S14.243 19.181 10 19.181s-6.364 0-7.682-1.318S1 14.424 1 10.181s0-6.364 1.318-7.682S5.757 1.181 10 1.181"
        data-name="Caminho 381"
      />
    </g>
  </svg>
);
export default SvgPencilEdit;
