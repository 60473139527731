import {NumericFormat} from "react-number-format";
import Str from "../../../helpers/Str";
import React from "react";

interface IInputNumber {
    label: string,
    value: any,
    setValue?: any,
    suffix?: string
    className?: string
    onBlur?: any
}

export default function InputNumber({
    label,
    value,
    setValue,
    suffix,
    className,
    onBlur
}:IInputNumber){
    return(
        <div className={Str.tw(className,'w-full md:col-span-3 xs:col-span-12')}>
            <label >
                {label}
                <NumericFormat
                    onBlur={onBlur}
                    onChange={(value) => setValue(value.target.value)}
                    value={value}
                    className={Str.tw(
                        'py-3 md:col-span-3 xs:col-span-12 text-[#939396] placeholder-[#939396]',
                        'bg-system-blue-dark border border-[#292932] rounded-md outline-none px-4 w-full',
                        'focus:border-border transition'
                    )}
                    allowLeadingZeros
                    suffix={suffix}
                />
            </label>
        </div>
    )
}