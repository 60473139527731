import menus from "../../../router/menus";
import {createElement} from "react";
import {Link, useLocation} from "react-router-dom";
export default function SideBar() {
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];

    return(
        <aside id="default-sidebar" className="w-64 h-full md:block xs:hidden" aria-label="Sidebar">
            <div className="h-full py-4 overflow-y-auto bg-system-blue-medium ">
                <ul className="space-y-4 font-medium">
                    {
                        menus.map((menu) => {
                            return(
                                <li>
                                    <Link to={menu.path} className={`flex items-center p-3 ${'/'+validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`}>
                                        <menu.icon width={20} height={20} />
                                        <span className="ms-3">{menu.title}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </aside>
    )
}