import * as React from "react";
import type { SVGProps } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20 22"
    {...props}
  >
    <g fill="none"  stroke="CurrentColor" strokeLinecap="round" strokeWidth={2}>
      <path
        d="m17.5 4.5-.62 10.025c-.158 2.561-.238 3.842-.88 4.763a4 4 0 0 1-1.2 1.128C13.842 21 12.559 21 9.993 21s-3.854 0-4.814-.585a4 4 0 0 1-1.2-1.13c-.642-.922-.719-2.2-.874-4.77L2.5 4.5"
        data-name="Caminho 333"
      />
      <path
        d="M1 4.5h18m-4.944 0-.683-1.408A4.45 4.45 0 0 0 12.3 1.4a2 2 0 0 0-.275-.172A4.45 4.45 0 0 0 10.035 1 4.46 4.46 0 0 0 8 1.234a2 2 0 0 0-.278.179 4.46 4.46 0 0 0-1.063 1.758L6.053 4.5"
        data-name="Caminho 334"
      />
      <path d="M7.5 15.5v-6" data-name="Caminho 335" />
      <path d="M12.5 15.5v-6" data-name="Caminho 336" />
    </g>
  </svg>
);
export default SvgDelete;
