import React from "react";
import PageContainer from "../../container/PageContainer";
import Str from "../../../helpers/Str";
import SvgArrowLeft02StrokeRounded from "../../Icons/ArrowLeft02StrokeRounded";

export type ILayoutDefault = {
    children: React.ReactNode;
    title?: string;
}
export default function LayoutDefault({
    children,
    title
}:ILayoutDefault){
    return(
        <PageContainer>
            <div className={Str.tw('h-[calc(95vh_-_90px)] overflow-y-auto border scrollbar  border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium')}>
                <head className={'p-5 border-b flex gap-4 items-center border-system-gray-gray80'}>
                    <div className={'text-xl font-bold'}>
                        {title}
                    </div>
                </head>
                {children}
            </div>
        </PageContainer>
    )
}