import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import workStatusApi from "../../services/workStatusApi";
import useTable from "../../hooks/useTable";
import Alert from "../../helpers/Alert";
import dashboardApi from "../../services/dashboardApi";

export default function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [cardSelected, setCardSelected] = useState('works_in_progress');
    const [statusProperties, setStatusProperties] = useState([
        {
            description: 'Obras em Andamento',
            data_consult: 'works_in_progress',
            quantity: 0,
        },
        {
            description: 'Obras Concluídas',
            data_consult: 'completed_works',
            quantity: 0,
        },
        {
            description: 'Casas MH',
            data_consult: 'casas_mh',
            quantity: 0,
        },
        {
            description: 'My Hunter',
            data_consult: 'my_hunter',
            quantity: 0,
        }
    ]);

    async function getData() {
        const {data, isError} = await dashboardApi.getDataDashboardQuantity();

        if (!isError){
            const newStatusProperties = [...statusProperties]

            newStatusProperties.map((status: any, index: number) => {
                newStatusProperties[index]['quantity'] = data[status.data_consult] === undefined ? 0 : data[status.data_consult]
            })
            setStatusProperties(newStatusProperties);
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])



    const { tableProps, loadData } = useTable({
        denyInitialLoad: true,
        dataLoader: async (params) => {
            const { data, isError } = await dashboardApi.getDataDashboard(cardSelected);
            if (isError) {
                Alert.error(data.message || data.error || 'Falha ao carregar dados.');
                return;
            }
            return data as any;
        }
    });

    useEffect(() => {
        loadData()
    }, [])

    const onClickCard = (dataConsult: string) => {
        setCardSelected(dataConsult)
    }

    return(
        <PageContainer>
            <div className={'flex flex-wrap gap-5 w-full md:justify-center'}>
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :

                        statusProperties.map((stPropertie) => {
                            return(
                                <div onClick={() => onClickCard(stPropertie.data_consult)} className={`flex bg-[#1C1C24] cursor-pointer flex-col items-center justify-center md:w-[250px] xs:w-full h-[162px] ${stPropertie.data_consult === cardSelected ? 'border-2 border-border' : ''} hover:border-2 hover:border-border rounded-lg`}>
                                    <div className={'font-semibold text-system-primary text-[36px]'}>
                                        {stPropertie.quantity}
                                    </div>
                                    <div className={'font-semibold'}>
                                        {stPropertie.description}
                                    </div>
                                </div>
                            )
                        })
                }
            </div>

        </PageContainer>
    )
}