import {IoIosClose} from "react-icons/io";
import noImage from "../../assets/img/noImage.png";
interface ICardProperty {
    property: any;
    onDelete: any;
}

export default function CardProperty({property, onDelete}: ICardProperty){
    return(
        <div className={'flex bg-[#1C1C24] hover:text-system-primary hover:border hover:border-border items-center rounded-md  md:w-[625px] md:h-[161px] text-[#B5B5BE] p-4 '}>
            <div className={'flex gap-2'}>
                <div>
                    <img className={'w-[111px] h-[111px] rounded-md border-2 border-border'} src={property.photo_path ? property.photo_path : noImage}/>
                </div>
                <div className={'flex flex-col gap-2'}>
                    <div className={'text-[17px] text-border text-[#B5B5BE]'}>
                        {property.description}
                    </div>
                    <div className={'text-[14px] text-[#B5B5BE]'}>
                        {
                            `${property.address}, Nº ${property.number}, Qd: ${property.block}, Lote: ${property.lot} - ${property.neighborhood} CEP: ${property.cep} - ${property.city} - ${property.state}`
                        }
                    </div>
                    <div className={'text-[14px] text-[#B5B5BE]'}>
                        <span className={'text-border'}>Situação:</span> {property.status_construction.description}
                    </div>
                </div>
                <div className={' mt-[-20px]'}  >
                    <button onClick={() => onDelete(property)}>
                        <IoIosClose size={25}/>
                    </button>
                </div>
            </div>
        </div>
    )
}