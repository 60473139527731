import noImage from '../../../assets/img/noImage.png';
import {ReactComponent as Notification} from "../../../assets/svg/notification.svg";
import {ReactComponent as Chat} from "../../../assets/svg/chat.svg";
import logoMh from '../../../assets/img/logo-branco-mh@2x.png'
import logoGrupo from '../../../assets/img/Grupo-88643-branco2x.png'
import {Link, useLocation} from "react-router-dom";
import menus from "../../../router/menus";
import {useState} from "react";
export default function Navbar() {
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];
    const [openMenu, setOpenMenu] = useState(false);

    return(
        <nav className="bg-system-blue-medium">
            <div className="mx-auto  px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-[80px] items-center justify-between">

                    <div className="flex flex-1 items-stretch justify-start">
                        <div className="flex  flex-shrink-0  gap-3">
                            <img className="w-auto 2xl:h-9 xl:h-9 lg:h-9 md:h-9 sm:h-9 max-[640px]:h-[20px] max-[640px]:w-[61px]"
                                 src={logoMh}
                                 alt="Your Company"/>
                                 <div className={'border-r-2 border-system-gray h-full'}></div>
                            <img className="w-auto 2xl:h-11 xl:h-11 lg:h-11 md:h-11 sm:h-9 max-[640px]:h-[24px] max-[640px]:w-[35px]"
                                 src={logoGrupo}
                                 alt="Your Company"/>
                        </div>
                    </div>
                    <div className="absolute gap-3 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <Link to={'/mensagens'} className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white">
                            <Chat/>
                        </Link>
                        <Link to={'/notificacao'} className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white">
                            <Notification/>
                        </Link>

                        <div className="relative ml-3">
                            <div>
                                <button type="button"
                                        className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none border-border border-2 "
                                        id="user-menu-button" aria-expanded="false" aria-haspopup="true">

                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src={noImage}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>

                        {/*Mobile menu button*/}
                        <div className="flex items-center sm:hidden">
                            <button type="button" onClick={() => setOpenMenu(!openMenu)} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                <span className="absolute -inset-0.5"></span>
                                <span className="sr-only">Open main menu</span>
                                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                </svg>
                                <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${openMenu ? '' : 'sm:hidden max-[640px]:hidden'}`} id="mobile-menu">
                <div className="space-y-1 px-2 pb-3 pt-2">
                    {
                        menus.map((menu) => {
                            return(
                                <Link to={menu.path} className={`flex items-center p-3 ${'/'+validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`} aria-current="page">
                                    <menu.icon width={20} height={20} />
                                    <span className="ms-3">{menu.title}</span>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </nav>

    )
}