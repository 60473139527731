import axios from "axios";

export default class ViaCepService{
    resource = 'https://viacep.com.br/ws/';
    consultaCep(cep: any): any {
        return axios.get(this.resource+cep+'/json/')
            .then((response) => {
                return response
            });
    }
}