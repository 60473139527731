import React, { useState } from 'react';

interface ITabs {
    tabsData: any[]
}
export function Tabs({tabsData}: ITabs) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return (
        <div>
            <div className="flex space-x-3 border-b border-system-gray-gray80 px-5">
                {/* Loop through tab data and render button for each. */}
                {tabsData.map((tab, idx) => {
                    return (
                        <button
                            key={idx}
                            className={`py-2 font-bold p-[2rem] h-[3.6rem] border-b-4 transition-colors duration-300 ${
                                idx === activeTabIndex
                                    ? 'border-border text-system-primary'
                                    : 'border-transparent hover:border-border hover:text-system-primary'
                            }`}
                            // Change the active tab on click.
                            onClick={() => setActiveTabIndex(idx)}
                        >
                            {tab.label}
                        </button>
                    );
                })}
            </div>
            {/* Show active tab content. */}
            <div className="">
                {tabsData[activeTabIndex].content}
            </div>
        </div>
    );
}