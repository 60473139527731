import PageContainer from "../../../components/container/PageContainer";
import FormLayout from "../../../components/layout/FormLayout";
import React, {useEffect, useState} from "react";
import userApi from "../../../services/userApi";
import Alert from "../../../helpers/Alert";
import Input from "../../../components/inputs/Input";
import InputSelect from "../../../components/inputSelect";
import noImage from '../../../assets/img/noImage.png';
import {Delete, ImageAdd} from "../../../components/Icons";
import InputImage from "../../../components/inputs/InputImage";
import {useParams} from "react-router";
import rolesApi from "../../../services/rolesApi";
export default function FormUsers() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const defaltValues = {
        id: '',
        cpf: '',
        name: '',
        email: '',
        phone: '',
        status: 1,
        role: '',
        type: 'admin',
        image: undefined
    }
    const [state, setState]: any = useState(defaltValues);
    const [image, setImage] = useState<File | undefined>();
    const [rolesOptions, setRolesOptions] = useState([]);
    useEffect(() => {
        getRoles();
        if (params.id){
            getData()
        }else{
            setLoading(false)
        }

    }, [])

    const getRoles = async () => {
        const {data, isError} = await rolesApi.getAllRolesWithPermitions();
        if (!isError){
            setRolesOptions(data.map((roles: any) => {
                return { value: roles.id, label: roles.name }
            }))
        }
    }


    const getData = async () => {
        setLoading(true);
        // @ts-ignore
        const {data, isError} = await userApi.one(params.id.toString());
        setState(
            {
                id: data.id,
                cpf: data.cpf,
                name: data.name,
                email: data.email,
                phone: data.phone,
                status: data.status ? 1 : 0,
                role: data.roles.length ? data.roles[0].id : '',
                type: 'admin',
                image: data.photo_path,
            }
        )
        setLoading(false);
    }

    const handleChange = (value: any, input: string) => {
        const newState = {...state}
        newState[input] = value
        if (!state.id){
            if (input === 'cpf'){
                newState['password'] = value
            }
        }
        setState(newState);
    };

    const statusOptions = [
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' },
    ];

    const onRemoveImage = () => {
        const newState = {...state};

        newState.image = '';
        setState(newState);

        setImage(undefined)
    }

    return(
        <FormLayout
            title={'Cadastro de Usuários'}
            urlReturn={'/usuarios'}
            state={state}
            setState={setState}
            apiData={userApi}
            defaltValues={defaltValues}
        >
            {
                loading ?
                <span className="flex items-center justify-center h-7">
                    <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
                :
                <div className={'flex md:flex-row xs:flex-col-reverse w-full p-5'}>
                        <div className={'md:w-3/4 sm:w-full grid'}>
                            <div className="grid grid-cols-12 gap-5">
                                <Input
                                    label={'ID'}
                                    value={state.id}
                                    className={'md:col-span-2 xs:col-span-12'}
                                    disabled
                                />
                                <Input
                                    label={'CPF'}
                                    className={'md:col-span-3 xs:col-span-12'}
                                    mask={'CPF'}
                                    value={state.cpf}
                                    required
                                    setValue={(v) => handleChange(v, 'cpf')}
                                />
                                <Input
                                    label={'Nome'}
                                    value={state.name}
                                    required
                                    className={'md:col-span-7 xs:col-span-12'}
                                    setValue={(v) => handleChange(v, 'name')}
                                />
                                <Input
                                    label={'E-mail'}
                                    value={state.email}
                                    required
                                    className={'md:col-span-5 xs:col-span-12'}
                                    inputType={'email'}
                                    setValue={(v) => handleChange(v, 'email')}
                                />
                                <Input
                                    label={'Telefone'}
                                    value={state.phone}
                                    className={'md:col-span-3 xs:col-span-12'}
                                    mask={'cellphone'}
                                    setValue={(v) => handleChange(v, 'phone')}
                                />
                                <InputSelect
                                    key={(Math.random() + 1).toString(36).substring(7)}
                                    selected={state.role}
                                    label={'Permissão'}
                                    className={'md:col-span-2 xs:col-span-12'}
                                    options={rolesOptions}
                                    onChangeSelect={(e: any) => handleChange(e.value, 'role')}
                                    placeholder={'Permissão'}
                                />

                                <InputSelect
                                    key={(Math.random() + 1).toString(36).substring(7)}
                                    /*selected={statusOptions.filter((status: any) => status.value === state.status)}*/
                                    selected={state.status}
                                    label={'Status'}
                                    className={'md:col-span-2 xs:col-span-12'}
                                    options={statusOptions}
                                    onChangeSelect={(e: any) => handleChange(e.value, 'status')}
                                    placeholder={'Status'}
                                />

                            </div>
                        </div>
                        <div className={'flex items-center p-3 ml-3 gap-2'}>
                            <InputImage
                                /*onAddImage={value => setImage(value)}*/
                                urlPrevew={state.image}
                                onAddImage={value => setState({...state, image: value})}
                                onRemoveImage={() => onRemoveImage()}
                            />

                        </div>
                    </div>
            }

        </FormLayout>
    )
}