import * as React from "react";
import type { SVGProps } from "react";
const SvgUserAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 22 20"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeWidth={2}
      transform="translate(-1 -2)"
    >
      <path strokeLinecap="round" d="M14 9h4" data-name="Caminho 395" />
      <path strokeLinecap="round" d="M14 12.5h3" data-name="Caminho 396" />
      <rect
        width={20}
        height={18}
        strokeLinejoin="round"
        data-name="Ret\xE2ngulo 737"
        rx={5}
        transform="translate(2 3)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 16c1.208-2.581 5.712-2.751 7 0"
        data-name="Caminho 397"
      />
      <path d="M10.5 9a2 2 0 1 1-2-2 2 2 0 0 1 2 2Z" data-name="Caminho 398" />
    </g>
  </svg>
);
export default SvgUserAccount;
