import Str from '../../../helpers/Str';

export type CheckboxProps = {
  value?: boolean;
  setValue?: (value: boolean) => void;
  className?: string;
  label?: string;
  onBlur?: any;
}

export function Checkbox({
  value,
  setValue,
  className,
  label,
  onBlur
}: CheckboxProps) {
  const id = Str.uniqId();

  return (
    <div className={Str.tw(className, 'flex items-center gap-1')}>
        <input
            onBlur={onBlur}
            onChange={() => setValue && setValue(!value)}
            checked={value}
            type="checkbox"
            value=""
            id={id}
            className="w-4 h-4 bg-black accent-system-primary border-[#292932] "
        />
        <label
            htmlFor={id}
            className="text-[#fafafb] font-regular"
        >
            {label}
        </label>
    </div>
  );
}
