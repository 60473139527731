import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/dashboard';

export default {
    async getDataDashboard(consult: string){
        try {
            const {data} = await api.get(endpoint+'?'+'consult='+consult);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async getDataDashboardQuantity(){
        try {
            const {data} = await api.get(endpoint+'/quantity');
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
}