import SideBar from "../SideBar";
import Navbar from "../NavBar";

export interface IPageContainer {
    children: React.ReactNode;
}

export default function PageContainer({children}: IPageContainer) {
    return(
        <div className={'md:h-screen md:overflow-hidden bg-system-blue-dark xs:pb-4'}>
            <Navbar/>
            <div className={'flex w-full md:h-full overflow-hidden'}>
                <SideBar/>
                <div className={'md:w-container xs:w-full  px-3 pt-5 max-h-full overflow-auto text-[#B5B5BE]'}>
                    {children}
                </div>
            </div>
        </div>
    )
}