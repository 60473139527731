import * as React from "react";
import type { SVGProps } from "react";
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 22.666 22.324"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="m12.324 1.324-11 5" data-name="Caminho 355" />
      <path
        d="M11.324 2.324v19h-5c-1.886 0-2.828 0-3.414-.586s-.586-1.528-.586-3.414v-11"
        data-name="Caminho 356"
      />
      <path d="m11.324 6.324 10 5" data-name="Caminho 357" />
      <path
        d="M9.324 21.324h7c1.886 0 2.828 0 3.414-.586s.586-1.529.586-3.414v-6.5"
        data-name="Caminho 358"
      />
      <path d="M17.324 9.324v-3" data-name="Caminho 359" />
      <path d="M6.324 10.324h1m-1 4h1" data-name="Caminho 360" />
      <path d="M15.324 13.324h1" data-name="Caminho 361" />
      <path d="M15.824 21.324v-4" data-name="Caminho 362" />
    </g>
  </svg>
);
export default SvgHouse;
