import * as React from "react";
import type { SVGProps } from "react";
const SvgImageAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21.75 21.75"
    {...props}
  >
    <path
      fill="#b5b5be"
      stroke="CurrentColor"
      d="M21 5.5A.75.75 0 0 0 21 4ZM13 4a.75.75 0 0 0 0 1.5ZM17.75.75a.75.75 0 0 0-1.5 0Zm-1.5 8a.75.75 0 0 0 1.5 0ZM21 4h-4v1.5h4Zm-4 0h-4v1.5h4ZM16.25.75v4h1.5v-4Zm0 4v4h1.5v-4Z"
      data-name="Caminho 366"
    />
    <path
      fill="none"
       stroke="CurrentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M10.5 1.75c-4.478 0-6.718 0-8.109 1.391S1 6.772 1 11.25s0 6.718 1.391 8.109S6.022 20.75 10.5 20.75s6.718 0 8.109-1.391S20 15.728 20 11.25v-.5"
      data-name="Caminho 367"
    />
    <path
      fill="none"
       stroke="CurrentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 12.885a13 13 0 0 1 1.872-.135 11.7 11.7 0 0 1 7.3 2.332A10.4 10.4 0 0 1 14 20.75"
      data-name="Caminho 368"
    />
    <path
      fill="none"
       stroke="CurrentColor"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 15.65a7.9 7.9 0 0 0-3.614-.9 8.68 8.68 0 0 0-5.386 2"
      data-name="Caminho 369"
    />
  </svg>
);
export default SvgImageAdd;
