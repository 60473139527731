import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowLeft02StrokeRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 18 12.796"
    {...props}
  >
    <g
      fill="none"
      stroke="#b5b5be"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M1 6.398h16" data-name="Caminho 326" />
      <path d="M6 11.398s-5-3.682-5-5 5-5 5-5" data-name="Caminho 327" />
    </g>
  </svg>
);
export default SvgArrowLeft02StrokeRounded;
