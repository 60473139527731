import PageContainer from "../../components/container/PageContainer";
import LayoutDefault from "../../components/layout/LayoutDefault";
import {Tabs} from "../../components/Tabs";
import {ConfigContent} from "./Config";
import {AccessProfile} from "./AccessProfile";
import {PropertyType} from "./PropertyType";
import {DocumentType} from "./DocumentType";
import {WorkStatus} from "./WorkStatus";

export default function Config() {

    const tabsData = [
        {
            label: 'Configurações',
            content:<ConfigContent/>,
        },
        {
            label: 'Perfil de Acessos',
            content: <AccessProfile/> ,
        },
        {
            label: 'Tipo de Imóvel',
            content: <PropertyType/>,
        },
        {
            label: 'Tipo de Documento',
            content: <DocumentType/>,
        },
        {
            label: 'Status da Obra',
            content: <WorkStatus/>,
        },
    ];
    return(
        <LayoutDefault title={'Configurações'}>
            <Tabs tabsData={tabsData}/>
        </LayoutDefault>
    )
}