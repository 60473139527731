import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import ListClean from "../../../components/layout/LayoutListClean";
import Input from "../../../components/inputs/Input";
import noImage from "../../../assets/img/noImage.png";
import Dropdown from "../../../components/dropdown/Dropdown";
import { HiDotsHorizontal } from "react-icons/hi";
import CardPosts from "../../../components/CardPost";
import propertyApi from "../../../services/propertyApi";
import postApi from "../../../services/postApi";
import Alert from "../../../helpers/Alert";
export default function ListPostsProperties() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [property, setProperty]: any = useState([]);
    const [dateConsult, setDateConsult] = useState(new Date().toISOString().split('T')[0]);
    useEffect(() => {
        getPostProperty()
    }, [])

    const getPostProperty = async () => {
        setLoading(true)
        // @ts-ignore
        const {data, isError} = await propertyApi.getPostsProperty(params.id.toString(), dateConsult);
        if (!isError){
            setProperty(data)
        }
        setLoading(false)
    }

    const onDeletePost = async (id: number | string) => {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir esse post?`
        );
        if (! confirm) return;
        setLoading(true)
        const {data, isError} = await postApi.destroy(id as string);
        if(!isError){
            Alert.success('Deletado com sucesso')
            await getPostProperty()
        }
    }

    const onChangeDate = (value: string) => {
        console.log(value)
        setDateConsult(value);
    }

    return(
        <ListClean
            client={false}
            title={'Atualizações do imóvel'}
            urlBack={`/propriedades/formulario/${params.id}`}
            input={<Input value={dateConsult} setValue={(v) => onChangeDate(v)} className={'w-[150px]  mt-[-15px] dark'}  type={'date'} />}
            descriptionProperty={property.description}
        >
            <div>
                {
                    loading?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <div className={'flex flex-col gap-5'}>
                            {
                                property.posts.length ?
                                    property.posts.map((post: any) => {
                                        return(
                                            <CardPosts
                                                id={post.id}
                                                description={post.description}
                                                images={post.files.filter((file: any) => file.type == 'image')}
                                                documents={post.files.filter((file: any) => file.type == 'file')}
                                                dataCreation={post.created_at}
                                                userCreate={post.user_create}
                                                onDeletePost={onDeletePost}
                                            />
                                        )
                                    })
                                    :
                                    ''
                            }
                        </div>



                }

            </div>
        </ListClean>
    )
}