import {IoClose} from "react-icons/io5";
import React from "react";
import noImage from "../../assets/img/noImage.png";

interface ICardClient {
    id?: string | number;
    name: string;
    url_photo: string;
    onClickUnlinkClient: any
}

export default function CardClient({
    id,
    name,
    url_photo,
    onClickUnlinkClient,
}: ICardClient) {
    return(
        <div key={id} className={'flex p-3 cursor-pointer justify-between items-center rounded bg-[#1C1C24] w-[270px] h-[68px] top-[591px] left-[290px] hover:border hover:border-border'}>
            <div className={'flex items-center gap-2'}>
                <img
                    className={'rounded-full w-[35px] h-[35px]'}
                    src={url_photo ? url_photo : noImage}
                />
                {name}
            </div>
            <div className={'flex items-center'}>
                <button type={'button'} onClick={onClickUnlinkClient}>
                    <IoClose className={'hover:text-border'} />
                </button>
            </div>
        </div>
    )
}