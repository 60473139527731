import Select from "react-select";
import Str from "../../helpers/Str";
import {IInputSelect} from "../../types";

/*export type SelectOption<V = string, L = string> = {
    value: V;
    label: L;
}*/

export default function InputSelect({
    selected,
    className,
    margintopClassName,
    label,
    classNameContainer,
    loading,
    options,
    onChangeSelect,
    placeholder,
    isMulti,
    isOptionDisabled,
    isDisabled,
    key,
    value,
    setValue,
    disabled,
    error,
    required,
    isClearable
}: IInputSelect){

    const inputSelectStyle = () => ({
        paddingBottom: '5px',
        paddingTop: '6px',
    });

    const meuPla =  'auto';
    /*if (menuPlacement !== undefined){
        console.log(menuPlacement);
        meuPla = menuPlacement;
    }*/

    const backgroundDifferentOptions = (isSelected: any, isFocused: any) => {
        if (isSelected && isFocused) return "#E0A422";
        if (!isSelected && isFocused) return "#E0A422";
        if (isSelected && !isFocused) return "#13131a";
        if (!isSelected && !isFocused) return "#13131a";
    };
    return(
        <div key={key} className={className ?? classNameContainer}>
            {
                label ?
                    <label>
                        {label}
                        {required ? <span className='text-red-600'> *</span> : ''}
                    </label>
                    :
                    ''
            }

            <Select
                /*menuPlacement={meuPla}*/
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                menuPlacement={'auto'}
                isClearable={isClearable}
                isLoading={loading}
                placeholder={placeholder ?? ''}
                value={value
                    ? (isMulti ? options.filter((opt) => value.includes(opt.value)) : options.filter((opt) => opt.value === value))
                    : (options ? options.filter((opt) => opt.value === selected)
                        : [])}
                /*value={
                    isMulti ?
                        options ? options.filter((opt) => selected.includes(opt.value)) : []
                        :
                        options ? options.filter((opt) => opt.value === selected) : []
                }*/
                className={Str.tw(
                    margintopClassName ? (margintopClassName) : (label ? 'mt-[0px]' : 'lg:mt-[1.5rem] sm:mt-[1rem] min-[320px]:mt-[5px] text-[#fff]'),
                )
                }
                styles={
                    {
                        option: (styles, { isFocused, isSelected}) => {
                            return ({...styles, color: isFocused ? "black" : "white", backgroundColor: backgroundDifferentOptions(isSelected, isFocused), borderColor: "#e0a422"})
                        },
                        input: (styles: any) => ({ ...styles, ...inputSelectStyle() }),
                        control: (styles: any) => ({ ...styles, /*height: '42px',*/ borderRadius: '0.375rem', backgroundColor: '#13131a'}),
                        menuList: (styles: any) => ({ ...styles,  backgroundColor: '#13131a'  }),
                        singleValue: (styles: any) => ({ ...styles,  color: '#939396'  }),
                        multiValue: (styles: any) => ({ ...styles,  color: 'white', backgroundColor: '#e0a422'  }),
                        multiValueLabel: (styles, { data }) => ({
                            ...styles,
                            color: 'white',
                        }),
                        multiValueRemove: (styles, { data }) => ({
                            ...styles,
                            ':hover': {
                                backgroundColor: 'e0a422',
                                color: 'white',
                            },
                        }),
                    }
                }
                options={options}
                onChange={setValue ? (e: any) => setValue(isMulti ? e.map((e: any) => e?.value ?? null) : e?.value ?? null) : onChangeSelect}
                isMulti={isMulti}
                isOptionDisabled={isOptionDisabled}
                isDisabled={isDisabled ?? disabled}
            />
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </div>
    )
}