import * as React from "react";
import type { SVGProps } from "react";
const SvgImage02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21 21"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeWidth={2}
      transform="translate(-1.5 -1.5)"
    >
      <path
        d="M2.5 12c0-4.478 0-6.718 1.391-8.109S7.522 2.5 12 2.5s6.718 0 8.109 1.391S21.5 7.522 21.5 12s0 6.718-1.391 8.109S16.478 21.5 12 21.5s-6.718 0-8.109-1.391S2.5 16.478 2.5 12Z"
        data-name="Caminho 363"
      />
      <circle
        cx={1.5}
        cy={1.5}
        r={1.5}
        data-name="Elipse 5"
        transform="translate(15 6)"
      />
      <path
        strokeLinejoin="round"
        d="M16 22a10.4 10.4 0 0 0-4.123-5.666A13.26 13.26 0 0 0 4.016 14q-.509 0-1.016.035"
        data-name="Caminho 364"
      />
      <path
        strokeLinejoin="round"
        d="M13 18a8.68 8.68 0 0 1 5.386-2 7.8 7.8 0 0 1 3.114.662"
        data-name="Caminho 365"
      />
    </g>
  </svg>
);
export default SvgImage02;
