import PageContainer from "../../../components/container/PageContainer";
import AddButton from "../../button/AddButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import Table from "../../Table";
import useTable from "../../../hooks/useTable";
import Alert from "../../../helpers/Alert";
import React, {useEffect, useState} from "react";
import Input from "../../inputs/Input";
import { CgSearch } from "react-icons/cg";
interface IListPageLayout {
    title: string,
    urlCreate: string,
    queries?: {
        [key: string]: string
    }
    table: {
        columns: any[];
        loader: any;
    },
    collunSearch?: string;
}

export default function ListPageLayout<T = any>(
{
    title,
    urlCreate,
    table,
    queries,
    collunSearch
}: IListPageLayout) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [dataSearch, setDataSerach] = useState('');

    const { tableProps, loadData } = useTable<T>({
        denyInitialLoad: true,
        dataLoader: async (params) => {
            //const q = searchParams.get("q");
            if (queries){
                params = queries;
            }

            if (dataSearch) {
                // @ts-ignore
                params = { ...params, like: [collunSearch, dataSearch] };
            }

            const { data, isError } = await table.loader({...params} as any);
            if (isError) {
                Alert.error(data.message || data.error || 'Falha ao carregar dados.');
                return;
            }
            return data as any;
        }
    });
    useEffect(() => {
        loadData()
    }, [])

    const onClickTable = (data?: any) => {
        navigate(urlCreate+'/'+data.id)
    }

    return(
        <PageContainer>
            <div className={'border border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium'}>
                <div className={'p-5 md:flex xs:flex-row border-b items-center justify-between border-system-gray-gray80'}>
                    <div className={'text-xl font-bold'}>
                        {title}
                    </div>
                    <div className={'flex'}>
                        <Input
                            placeholder={'Procurar'}
                            className={'w-[55rem]'}
                            value={dataSearch}
                            setValue={(v) => setDataSerach(v)}
                        />
                        <button onClick={loadData}><CgSearch size={40} color={'#E0A422'} /></button>
                    </div>

                </div>

                <div className="table_component" role="region">
                    <Table
                        {...tableProps}
                        onClick={onClickTable}
                        data={tableProps.data}
                        columns={table.columns}
                    />
                </div>


                <AddButton onClick={() => navigate(urlCreate)}/>

            </div>

        </PageContainer>
    )
}