import React, { useState, useEffect } from 'react';
import background from '../../../assets/img/bg-login-2x.png'
import logoMh from '../../../assets/img/logo-branco-mh@2x.png'
import logoGrupo from '../../../assets/img/Grupo-88643-branco2x.png'
import Form from "../../../components/form/Form";
import {LoginData} from "../../../types";
import useForm from "../../../hooks/useForm";
import Input from "../../../components/inputs/Input";
import {Checkbox} from "../../../components/inputs/Checkbox";
import Button from "../../../components/button/Button";
import Storage from '../../../helpers/Storage';
import Alert from "../../../helpers/Alert";
import authApi from "../../../services/authApi";
import useAppContext from "../../../hooks/useAppContext";
export default function Login() {
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();
    const { form, isFormInvalid, handleChange } = useForm<LoginData>({
        validator: (data) => data.password && data.email
    });
    async function handleSubmit() {
        setLoading(true);
        const { data, isError } = await authApi.login(form);
        if (isError) {
            setLoading(false);
            Alert.error(data.message);
            return;
        }
        Storage.setApiToken(data.token);
        Storage.setUserData({ usuario: data.usuario });
        appContext.setUsuario(data.usuario);
        setLoading(false);
        window.location.href = '/dashboard';
    }
    return(
        <section
            className="h-screen  bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.67),rgba(0,0,0,0.67)),url(${background})` }}
        >
            <div className="container h-full">
                <div className="grid  lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 min-[450px]:grid-cols-1 content-center justify-items-center h-full" >
                    <div className="col-span-1 flex items-center md:mb-[50px] sm:mb-[50px] max-[621px]:mb-[50px]">
                        <div className={'flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col max-[621px]:flex-col md:items-center sm:items-center max-[621px]:items-center 2xl:gap-[90px] xl:gap-[90px] lg:gap-[90px]  md:gap-[10px] sm:gap-[10px] max-[621px]:gap-[10px]   gap-[90px]'}>
                            <img src={logoMh} className={'w-[200px]'}/>
                            <img src={logoGrupo} className={'w-[140px]'}/>
                        </div>

                    </div>
                    <div className="col-span-1">
                        <div className="border border-[#1C1C24] rounded-md bg-[#1C1C24]  max-[450px]:w-[350px] pb-[60px] w-[469px] ">
                            <div className={" h-full px-[50px] mt-[60px]"}>
                                <h1 className="text-[27px] font-bold text-[#F7F7F8] ">
                                    Fazer Login
                                </h1>
                                <div className={'text-base font-semibold text-[#696974] mt-[23px] mb-[23px]'}>
                                    Digite seus dados para acessar
                                </div>
                                <Form className="mt-5 grid gap-[22px]"  onSubmit={() => handleSubmit()}>
                                    <Input
                                        large
                                        placeholder={'E-mail'}
                                        inputType="email"
                                        value={form?.email}
                                        setValue={(v) => handleChange(v, 'email')}
                                        required
                                    />
                                    <Input
                                        large
                                        placeholder={'Senha'}
                                        inputType="password"
                                        value={form?.password}
                                        setValue={(v) => handleChange(v, 'password')}
                                        required
                                    />
                                    <div className={'mt-7 flex justify-between'}>
                                        <Checkbox
                                            className="col-span"
                                            label="Lembrar senha"
                                            setValue={(v) => handleChange(v, 'password')}
                                        />
                                        <div className={'text-[#fafafb] font-regular'}>
                                            Esqueceu a senha?
                                        </div>
                                    </div>
                                    <Button loading={loading} type={'submit'} className={'col-span-1'}>Entrar</Button>

                                    <div className={'border border-[#23232C] mt-1 mb-1'}></div>

                                    <div className={'text-[#fafafb] font-regular'}>
                                        Novo usuário?
                                        <a className="text-system-primary ml-2 transition cursor-pointer" href={'/cadastro'}>
                                            Crie uma conta
                                        </a>
                                    </div>


                                </Form>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}