import {ITableBody} from "../../../types";
import Str from "../../../helpers/Str";

export default function TableBody({columns, tableData, onClick}: ITableBody) {
    return(
        <tbody>
            {
                tableData && tableData.map((record: any, index) => {
                    return(
                        <tr key={index} onClick={() => onClick(record)} className={`hover:bg-[#6B5324] odd:bg-[#1C1C24]  cursor-pointer border-t border-system-gray-gray80`}>
                            {
                                columns && columns.map((column: any) => {

                                    if (column.type === 'status') {
                                        return (
                                            <td className="py-5 px-2">
                                                {record[column.index] ? 'Ativo' : 'Inativo'}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'phone') {
                                        return (
                                            <td className="py-5 px-2">
                                                {Str.addPhoneMask(record[column.index])}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'dateT') {
                                        return (
                                            <td className="py-5 px-2">
                                                {Str.convertDateCreatedAt(record[column.index])}
                                            </td>
                                        )
                                    }

                                    const concat = column.index.split("+");
                                    if (concat.length > 1){
                                        let fieldsRealtionchipPrimary = concat[0].split(".")
                                        let fieldsRealtionchipSecondary = concat[1].split(".")
                                        if (fieldsRealtionchipPrimary.length > 1 && fieldsRealtionchipSecondary.length > 1){

                                            const firstDt = record[fieldsRealtionchipPrimary[0]][fieldsRealtionchipPrimary['1']];
                                            const lastDt = record[fieldsRealtionchipSecondary[0]][fieldsRealtionchipSecondary['1']]

                                            return (
                                                <td className="py-5 px-2">
                                                    {firstDt} {lastDt}
                                                </td>
                                            )
                                        }
                                        return (
                                            <td className="py-5 px-2">
                                                {record[concat[0]]} {record[concat[1]]}
                                            </td>
                                        )
                                    }



                                    return(
                                        <td className="py-5 px-2">
                                            {record[column.index]}
                                        </td>
                                    )
                                })
                            }

                        </tr>
                    )
                })
            }
        </tbody>
    )
}