import * as React from "react";
import type { SVGProps } from "react";
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 18 17"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        d="M9 11V1m0 10c-.7 0-2.008-1.994-2.5-2.5M9 11c.7 0 2.009-1.994 2.5-2.5"
        data-name="Caminho 344"
      />
      <path
        d="M17 13c0 2.482-.518 3-3 3H4c-2.482 0-3-.518-3-3"
        data-name="Caminho 345"
      />
    </g>
  </svg>
);
export default SvgDownload;
