import * as React from "react";
import type { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21.493 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeWidth={2}>
      <path
        strokeLinecap="round"
        d="m20.001 6.133-.494-.857c-.373-.648-.56-.972-.878-1.1s-.677-.027-1.4.177l-1.22.344a2 2 0 0 1-1.359-.169l-.337-.194a2 2 0 0 1-.788-.968l-.334-1c-.22-.66-.329-.99-.591-1.179s-.609-.189-1.3-.189h-1.111c-.694 0-1.042 0-1.3.189s-.371.519-.591 1.179l-.334 1a2 2 0 0 1-.788.968l-.337.194a2 2 0 0 1-1.361.164l-1.22-.34c-.718-.2-1.077-.306-1.4-.177s-.5.453-.878 1.1l-.494.857c-.35.607-.525.911-.491 1.234s.268.584.737 1.1l1.031 1.153a2.41 2.41 0 0 1 0 2.75l-1.026 1.158c-.468.521-.7.781-.737 1.1s.141.627.491 1.234l.494.857c.373.648.56.972.878 1.1s.677.027 1.4-.177l1.22-.344a2 2 0 0 1 1.359.17l.337.194a2 2 0 0 1 .788.968l.334 1c.22.66.329.99.591 1.179s.609.189 1.3.189h1.112c.694 0 1.042 0 1.3-.189s.371-.519.591-1.179l.334-1a2 2 0 0 1 .788-.968l.337-.194a2 2 0 0 1 1.359-.17l1.22.344c.718.2 1.077.306 1.4.177s.5-.453.878-1.1l.494-.857c.35-.607.525-.911.491-1.234s-.268-.584-.737-1.1l-1.031-1.153a2.41 2.41 0 0 1 0-2.75l1.028-1.152c.468-.521.7-.781.737-1.1s-.142-.631-.492-1.239Z"
        data-name="Caminho 385"
      />
      <path
        d="M14.203 10.992a3.5 3.5 0 1 1-3.5-3.5 3.5 3.5 0 0 1 3.5 3.5Z"
        data-name="Caminho 386"
      />
    </g>
  </svg>
);
export default SvgSettings;
