import Input from "../../../components/inputs/Input";
import React, {useEffect, useState} from "react";
import InputSelect from "../../../components/inputSelect";
import Button from "../../../components/button/Button";
import Form from "../../../components/form/Form";
import configirationApi from "../../../services/configirationApi";
import Alert from "../../../helpers/Alert";
interface IConfiguration {
    id?: string | number;
    send_email: boolean;
    comment: boolean;
    api: string;
}
export function ConfigContent() {
    const [state, setState] = useState<IConfiguration>();
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const options = [
        { value: true, label: 'Sim' },
        { value: false, label: 'Não' },
    ];
    const handleChange = (value: string|boolean, input: string) => {
        // @ts-ignore
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getData();
    },[])

    const getData = async () => {
        const {data, isError} = await configirationApi.one('1');
        if (!isError){
            setState(data);
        }
        setLoading(false);
    }

    async function onSubmit(){
        setLoadingBtn(true)
        // @ts-ignore
        const {data, isError} = await configirationApi.update(state.id, state);
        if (!isError){
            Alert.success("Configrações atualizadas com sucesso!")
        }
        setLoadingBtn(false)
    }

    return(
        <>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-system-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <Form className={'h-[calc(73vh_-_70px)]'} onSubmit={onSubmit}>
                        <div className={'p-4'}>
                            <div className="grid grid-cols-12 gap-5">
                                <InputSelect
                                    selected={state?.send_email}
                                    label={'Enviar por e-mail'}
                                    className={'md:col-span-5 xs:col-span-12'}
                                    options={options}
                                    onChangeSelect={(e: any) => handleChange(e.value, 'send_email')}
                                    placeholder={'Enviar por e-mail'}
                                />
                            </div>
                            <div className="grid grid-cols-12 gap-5 mt-4">
                                <InputSelect
                                    selected={state?.comment}
                                    label={'Comentários'}
                                    className={'md:col-span-5 xs:col-span-12'}
                                    options={options}
                                    onChangeSelect={(e: any) => handleChange(e.value, 'comment')}
                                    placeholder={'Comentários'}
                                />
                            </div>
                            <div className="grid grid-cols-12 gap-5 mt-4">
                                <Input
                                    setValue={(v) => handleChange(v, 'api')}
                                    value={state?.api}
                                    className={'md:col-span-7 xs:col-span-12'}
                                    label={'API'}
                                />
                            </div>
                        </div>
                        <footer className={'sticky top-[100vh] flex gap-4 pt-5 px-4 border-t border-system-gray-gray80'}>
                            <Button loading={loadingBtn} type={'submit'} className={'w-[100px]'}>
                                Salvar
                            </Button>
                        </footer>
                    </Form>
            }
        </>

    )
}