import * as React from "react";
import type { SVGProps } from "react";
const SvgUserSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21 21"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeWidth={2}
      data-name="user-square-stroke-rounded (1)"
    >
      <path
        d="M1 10.5c0-4.478 0-6.718 1.391-8.109S6.022 1 10.5 1s6.718 0 8.109 1.391S20 6.022 20 10.5s0 6.718-1.391 8.109S14.978 20 10.5 20s-6.718 0-8.109-1.391S1 14.978 1 10.5Z"
        data-name="Caminho 399"
      />
      <path
        strokeLinecap="round"
        d="M6 15.5a6.34 6.34 0 0 1 9 0M13 8a2.5 2.5 0 1 1-2.5-2.5A2.5 2.5 0 0 1 13 8Z"
        data-name="Caminho 400"
      />
    </g>
  </svg>
);
export default SvgUserSquare;
