import * as React from "react";
import type { SVGProps } from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21.861 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeWidth={2}>
      <path
        strokeLinecap="round"
        d="M13.93 16a5 5 0 0 1-6 0"
        data-name="Caminho 353"
      />
      <path
        strokeLinejoin="round"
        d="M1.282 12.214c-.353-2.3-.53-3.446-.1-4.464s1.4-1.715 3.325-3.108L5.951 3.6C8.348 1.867 9.547 1 10.93 1s2.582.867 4.979 2.6l1.44 1.041c1.927 1.393 2.891 2.09 3.325 3.108s.258 2.167-.1 4.464l-.3 1.959c-.5 3.256-.751 4.885-1.919 5.856S15.484 21 12.069 21H9.791c-3.415 0-5.122 0-6.29-.971s-1.418-2.6-1.919-5.856Z"
        data-name="Caminho 354"
      />
    </g>
  </svg>
);
export default SvgHome;
