import noImage from "../../assets/img/noImage.png";
import Dropdown from "../dropdown/Dropdown";
import {HiDotsHorizontal} from "react-icons/hi";
import React from "react";
import Str from "../../helpers/Str";
import {IoClose} from "react-icons/io5";
import {Download} from "../Icons";
interface ICardPosts {
    images?: any;
    description?: string;
    documents?: any;
    dataCreation?: string;
    userCreate: any;
    id: number;
    onDeletePost?: any;
}
export default function CardPosts({images, description, documents, dataCreation, userCreate, id, onDeletePost}: ICardPosts) {
    const dropDownOptions = [
        {
            label: 'Editar',
            onClick: () => console.log('editar')
        },
        {
            label: 'Excluir',
            onClick: () => onDeletePost(id)
        }
    ]

    return(
        <div className={'rounded-xl  w-full bg-[#1C1C24] p-5'}>
            <div className={'flex justify-between'}>
                <div className={'flex gap-3 items-center'}>
                    <div>
                        <img className={'w-[50px] h-[50px] rounded-full '} src={userCreate.photo_path ? userCreate.photo_path : noImage}/>
                    </div>
                    <div >
                        <div className={'text-[#FAFAFB] text-[20px]'}>
                            {userCreate.name}
                        </div>
                        <div className={'text-[#696974] text-[14px]'}>
                            {Str.convertDateStringComplet(dataCreation)}
                        </div>
                    </div>
                </div>
                <div>
                    <Dropdown
                        trigger={<HiDotsHorizontal
                            size={20}
                            color={'#92929D'}
                        />}
                        items={dropDownOptions}
                    />
                </div>
            </div>
            <div className={'mt-5 text-[16px] text-[#B5B5BE]'}>
                {description}
            </div>
            <div className={'mt-5'}>
                <div className="grid grid-cols-2 gap-6">
                    {
                        images.length ?
                            images.length === 1 ?
                                <div className={'col-span-2'}>
                                    <img className="h-auto w-full rounded-lg " src={images[0].url_path}/>
                                </div>
                                :
                                <>
                                    {
                                        images.map((image: any) => {
                                            return(
                                                <div className={'md:col-span-1 sm:col-span-2 xs:col-span-2'}>
                                                    <img
                                                        className="h-[350px] w-[350px] rounded-lg"
                                                        src={image.url_path}
                                                    />
                                                </div>
                                            );
                                        })
                                    }


                                </>
                            :
                            ''
                    }
                </div>
                {
                    documents.length ?
                        <div className={'flex flex-col w-full mt-5 gap-5'}>
                            {
                                documents.map((document: any, index: number) => {
                                    return  (
                                        <div className={'flex border border-[#13131A] hover:border-border items-center justify-between w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                            <div className={'text-[#B5B5BE]'}>
                                               {document.original_name}
                                            </div>
                                            <div className={'flex w-[50%] text-[#64646C] justify-between'}>
                                                <div >
                                                    {Str.convertDateCreatedAt(document.created_at)}
                                                </div>
                                                <div>
                                                    <button onClick={()=> window.open(document.url_path, "_blank")} className={'hover:text-border'}>
                                                        <Download/>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        ''
                }
            </div>


        </div>
    )
}