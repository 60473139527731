import * as React from "react";
import type { SVGProps } from "react";
const SvgSearchSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21 21"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeWidth={2}>
      <path
        d="M1 10.5c0-4.478 0-6.718 1.391-8.109S6.022 1 10.5 1s6.718 0 8.109 1.391S20 6.022 20 10.5s0 6.718-1.391 8.109S14.978 20 10.5 20s-6.718 0-8.109-1.391S1 14.978 1 10.5Z"
        data-name="Caminho 383"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m13.5 13.5 2 2m-1-5.5a4.5 4.5 0 1 0-4.5 4.5 4.5 4.5 0 0 0 4.5-4.5"
        data-name="Caminho 384"
      />
    </g>
  </svg>
);
export default SvgSearchSquare;
