import * as React from "react";
import type { SVGProps } from "react";
const SvgDocumentAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeLinecap="round" strokeWidth={2}>
      <path
        d="M19 10V9c0-3.771 0-5.657-1.24-6.828S14.522 1 10.529 1H9.471C5.478 1 3.481 1 2.24 2.172S1 5.229 1 9v4c0 3.771 0 5.657 1.24 6.828S5.478 21 9.471 21H10"
        data-name="Caminho 340"
      />
      <path d="M6 6h8" data-name="Caminho 341" />
      <path d="M6 11h5" data-name="Caminho 342" />
      <path
        d="M19 19.647V16a3.157 3.157 0 0 0-3-3 3.157 3.157 0 0 0-3 3v3.5a1.643 1.643 0 0 0 3.273 0v-2.735"
        data-name="Caminho 343"
      />
    </g>
  </svg>
);
export default SvgDocumentAttachment;
