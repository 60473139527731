import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Str from '../../../helpers/Str';


export interface DropdownItem {
  label: string;
  onClick?: any;
}

export interface DropdownProps {
  className?: string;
  trigger?: React.ReactNode;
  items?: DropdownItem[];
  consult?: any;
  onPageChange?: any;
  dataPaginate?: any;
  onClickItem?: any;
  onSelectDropDown?: any;
  loading?: boolean;
}

export default function Dropdown({
  className,
  trigger,
  items,
  consult,
  onPageChange,
  dataPaginate,
  onClickItem,
  onSelectDropDown,
  loading
}: DropdownProps) {
  return (
    <Menu as="div" className={Str.tw(className, 'relative inline-block text-left')}>
      <div>
        <Menu.Button>
          {
            loading ?
              <span className="flex items-center justify-center h-7">
                  <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
              :
              trigger
          }
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute z-10 right-0 mt-2 ${consult ? 'w-[380px]' : 'w-56'} text-[#92929D]  origin-top-right divide-y divide-gray-100 rounded-md bg-[#13131A] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>

            <div className="px-1 py-1">
                {
                        items?.map((item, i) => (
                            <Menu.Item key={i}>
                                {({ active }) => (
                                    <button
                                        onClick={item.onClick}
                                        className={`${
                                            active ? 'bg-primary text-in-primary' : ''
                                        } 
                                        
                                        group flex w-full items-center  px-2 py-2 text-sm `}
                                    >
                                        {item.label}
                                    </button>
                                )}
                            </Menu.Item>
                        ))
                }
          </div>
            
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
