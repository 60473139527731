import * as React from "react";
import type { SVGProps } from "react";
const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 19 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeLinejoin="round" strokeWidth={2}>
      <path
        d="M1 9c0-3.771 0-5.657 1.245-6.828S5.493 1 9.5 1h.773c3.261 0 4.892 0 6.024.8a4.1 4.1 0 0 1 .855.805C18 3.671 18 5.205 18 8.275v2.545c0 2.963 0 4.445-.469 5.628a7.23 7.23 0 0 1-4.369 4.112C11.9 21 10.33 21 7.182 21a11.3 11.3 0 0 1-3.417-.252 4.13 4.13 0 0 1-2.5-2.35A9.4 9.4 0 0 1 1 15.182Z"
        data-name="Caminho 346"
      />
      <path
        strokeLinecap="round"
        d="M18 11a3.333 3.333 0 0 1-3.333 3.333 8.5 8.5 0 0 0-2.1.057 1.67 1.67 0 0 0-1.179 1.179 8.5 8.5 0 0 0-.057 2.1A3.333 3.333 0 0 1 8 21"
        data-name="Caminho 347"
      />
      <path strokeLinecap="round" d="M5.5 6h7" data-name="Caminho 348" />
      <path strokeLinecap="round" d="M5.5 10h3" data-name="Caminho 349" />
    </g>
  </svg>
);
export default SvgFile;
