import InputMask from 'react-input-mask';
import Str from '../../../helpers/Str';

export interface InputProps {
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string;
    mask?: string | 'cm' | 'kg' | 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'phone' | 'cellphone' ;
    value?: string | number;
    inputType?: 'longitude' | 'latitude' | 'UF' | 'CEP' | 'CPF' | 'CNPJ' | 'clientCode' | 'password' | 'email' | 'time';
    setValue?(value: string): void;
    large?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    type?: 'number' | 'date' | 'time';
    min?: number;
    name?: string;
    key?: string;
    required?: boolean;
    OnBlur?: any;
}

export default function Input({
  className,
  value,
  label,
  error,
  setValue,
  mask = '',
  inputType,
  placeholder,
  large,
  hideLabel,
  type,
  name,
  key,
  required,
  OnBlur,
  ...rest
}: InputProps) {
    /*const finalPlaceholder =
        inputType === 'longitude' ? 'Longitude' :
            inputType === 'latitude' ? 'Latitude' :
                inputType === 'UF' ? 'UF' :
                    inputType === 'CEP' ? 'CEP' :
                        inputType === 'CPF' ? 'CPF' :
                            inputType === 'CNPJ' ? 'CNPJ' :
                                inputType === 'clientCode' ? 'Código de cliente' :
                                    inputType === 'password' ? 'Senha' :
                                        inputType === 'email' ? 'email' :
                                            placeholder;*/

    const finalMask = (mask === 'CPF' || inputType === 'CPF') ? '999.999.999-99' :
        (mask === 'cm') ? '99999,99' :
            (mask === 'kg') ? '99999,99' :
                (mask === 'phone') ? '(99) 9999-9999' :
                    (mask === 'cellphone') ? '(99) 9 9999-9999' :
                        (mask === 'longitude' || inputType === 'longitude') ? '' :
                            (mask === 'latitude' || inputType === 'latitude') ? '' :
                                (mask === 'CEP' || inputType === 'CEP') ? '99.999-999' :
                                    (mask === 'UF' || inputType === 'UF') ? 'aa' :
                                        (mask === 'clientCode' || inputType === 'clientCode') ? '99.999.999/9999-99' :
                                            (mask === 'CNPJ' || inputType === 'CNPJ') ? '99.999.999/9999-99' :
                                                mask;

    const finalType = inputType === 'password' ? 'password' : type;

    function handleOnChange(event: any): void {
        if (! setValue) return;

        let value = event.target.value as string;

        if (
            mask === 'CPF' || inputType === 'CPF' ||
            mask === 'clientCode' || inputType === 'clientCode' ||
            mask === 'CNPJ' || inputType === 'CNPJ' ||
            mask === 'CEP' || inputType === 'CEP' ||
            mask === 'longitude' || inputType === 'longitude' ||
            mask === 'latitude' || inputType === 'latitude' ||
            mask === 'phone' || mask === 'cellphone'
        ) value = Str.removeNonNumbers(value);

        if (mask === 'cm' || mask === 'kg') {
            value = value.replaceAll(',__', '').replaceAll('_', '');
        }

        if (mask === 'UF' || inputType === 'UF') value = value.toUpperCase();

        setValue(value);
    }

    return (
        <label className={Str.tw('w-full', className)}>
            {!hideLabel && <span>{label} {(label && required) ? <span className='text-red-600'>*</span> : ''}</span>}
            <InputMask
                required={required}
                key={key}
                {...rest}
                name={name}
                maskChar=""
                type={finalType}
                mask={finalMask}
                placeholder={placeholder}
                value={(type === 'date' && value?.toString().length === 'XXXX-XX-XX XX:XX:XX'.length) ? value.toString().substring(0, 'XXXX-XX-XX'.length)  : value}
                onChange={handleOnChange}
                onBlur={OnBlur}
                className={Str.tw(
                    large ? 'py-3' : 'py-2',
                    'text-[#939396] placeholder-[#939396]',
                    'bg-system-blue-dark border border-[#292932] rounded-md outline-none px-4 w-full',
                    'focus:border-border transition'
                )}
            />
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </label>
    );
}