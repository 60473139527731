import * as React from "react";
import type { SVGProps } from "react";
const SvgUploadSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 21 21"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6.5v8m0-8C9.8 6.5 8.492 8.494 8 9m2.5-2.5c.7 0 2.009 1.994 2.5 2.5"
        data-name="Caminho 393"
      />
      <path
        d="M1 10.5c0-4.478 0-6.718 1.391-8.109S6.022 1 10.5 1s6.718 0 8.109 1.391S20 6.022 20 10.5s0 6.718-1.391 8.109S14.978 20 10.5 20s-6.718 0-8.109-1.391S1 14.978 1 10.5Z"
        data-name="Caminho 394"
      />
    </g>
  </svg>
);
export default SvgUploadSquare;
