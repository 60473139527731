import { GoPlus } from "react-icons/go";
interface IAddButton {
    onClick?: (event: any) => void;
}
export default function AddButton({onClick}: IAddButton) {
    return(
        <div className="absolute bottom-10 right-10 flex">
            <button type={'button'} onClick={onClick} className={'w-[60px] h-[60px] text-[20px] bg-border rounded-full flex justify-center items-center font-bold '}>
                <GoPlus size={35} color={'#fff'}/>
            </button>
        </div>
    )
}