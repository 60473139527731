import SvgArrowLeft02StrokeRounded from "../../Icons/ArrowLeft02StrokeRounded";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

export interface IListListClean {
    children: React.ReactNode;
    client?: boolean;
    title?: string;
    urlBack: string;
    input?: any;
    descriptionProperty?: string;
}
export default function ListClean({children, client, title, urlBack, input, descriptionProperty}: IListListClean){
    const navigate = useNavigate();

    return(
        <div className={'flex h-full justify-center xs:pb-4 '}>
            <div className={'md:w-[50%] xs:w-full scrollbar'}>
                {
                    <div className={'flex justify-between items-center text-[#B5B5BE] pt-5 pb-5 '}>
                        <button onClick={() => navigate(urlBack)} className={'flex items-center gap-2 font-bold'}>
                            <SvgArrowLeft02StrokeRounded color={'#B5B5BE'} width={20}/>Voltar
                        </button>
                        <div className={`${client ? 'md:mr-[8rem] xs:mr-[4rem] text-[20px]' : 'text-[20px]'} `}>
                            {title}
                        </div>

                        <div className={'flex gap-3 items-center'}>
                            <div>
                            {
                                input ?
                                    input
                                    :
                                    ''
                            }
                            </div>

                            {

                                !client ?
                                    <div className={'border border-[#292932] p-4 rounded-md text-border'}>
                                        {descriptionProperty}
                                    </div>
                                    :
                                    ''
                            }

                        </div>


                    </div>
                }
                {children}
            </div>

        </div>
    )
}