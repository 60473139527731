import api from "./api";
export default {
    async getAllRolesWithPermitions(){
        try {
            const {data} = await api.get('/role');
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async getAllPermissions(){
        try {
            const {data} = await api.get('/permissions');
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async createRole(formData: any){
        try {
            const {data} = await api.post(`role`, formData);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async updateRole(id: number, formData: any){
        try {
            const {data} = await api.put(`role/${id}`, formData);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },

    async deleteRole(id: number){
        try {
            const {data} = await api.delete(`role/${id}`);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
}